<template>
  <n-grid cols="1 s:8" responsive="screen">
    <n-gi span="1 s:4" offset="0 s:1"><ReviewStepper v-if="rent" :rent="rent" /></n-gi
    ><n-gi span="0 s:2"
      ><div class="rent-resumen">
        <Header v-if="rent" :rent="rent" showTitleInH6 hideState /><rent-resumen
          v-if="rent"
          :publicationTitle="rent.publication.title"
          :reservedDates="rent.requestedRangeOfDates"
          :executedDates="rent.executedRangeOfDates"
          fromMaintainer
          fullscreen
        /></div></n-gi></n-grid
  ><footer-mobile
    v-if="isMobile"
    haveDetails
    @expand="onExpandFooterMobileChange"
    :detailDrawerHeight="`${(parseInt(windowsHeight) - 80).toString()}px`"
    :showResume="false"
  >
    <div class="rent-resumen">
      <Header
        v-if="rent"
        :rent="rent"
        showTitleInH6
        :showDefaultImage="false"
        :bordered="false"
        hideState
      /><rent-resumen
        v-if="rent"
        :publicationTitle="rent.publication.title"
        :reservedDates="rent.requestedRangeOfDates"
        :executedDates="rent.executedRangeOfDates"
        fromMaintainer
      /></div
  ></footer-mobile>
</template>

<script>
import { defineComponent, ref, computed, onMounted, onUnmounted } from "vue";
import { NGrid, NGi, useDialog } from "naive-ui";
import responsive from "@/mixins/responsive";
import FooterMobile from "@/components/FooterMobile.vue";
import Header from "@/components/rent/Header.vue";
import { useStore } from "vuex";
import RentResumen from "@/components/rent/Resumen.vue";
import { useRouter } from "vue-router";
import ReviewStepper from "@/components/rent/Review/Stepper.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ReviewWriter",
  components: {
    NGrid,
    NGi,
    FooterMobile,
    Header,
    RentResumen,
    ReviewStepper,
  },
  mixins: [responsive],
  props: {
    rentId: { type: String, required: true },
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const router = useRouter();
    const dialog = useDialog();
    const store = useStore();
    const footerMobileExpanded = ref(false);
    const rentRef = computed(() => store.state.maintainer_rents.detail);

    onMounted(async () => {
      await store.dispatch("app/lockUI");
      await store.dispatch("maintainer_rents/loadDetail", {
        id: props.rentId,
        userId: store.getters["auth/user_id"],
      });
      await store.dispatch("app/unlockUI");
      if (rentRef.value.state !== "FINISHED" && rentRef.value.state !== "RETURNED") {
        dialog.warning({
          title: t("rent.review.dialogs.rentMustBeFinished.title"),
          content: t("rent.review.dialogs.rentMustBeFinished.content"),
          positiveText: t("rent.review.dialogs.rentMustBeFinished.positiveButton.text"),
          onPositiveClick: () =>
            router.push({
              name: "view-rent-detail",
              params: { id: props.rentId },
            }),
        });
      }
    });
    onUnmounted(() => {
      store.dispatch("maintainer_rents/unloadDetail");
    });

    return {
      t,
      rent: rentRef,
      footerMobileExpanded,
      onExpandFooterMobileChange: (expanded) => (footerMobileExpanded.value = expanded),
      currentUser: computed(() => store.getters["auth/user_id"]),
    };
  },
});
</script>
