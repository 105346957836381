<template>
  <n-form
    :label-placement="isMobile ? 'top' : 'left'"
    require-mark-placement="right-hanging"
    label-width="200px"
    :style="{
      maxWidth: '640px',
    }"
    :model="model"
    ref="form"
    ><n-steps
      vertical
      :current="current"
      :status="currentStatus"
      class="mt-2 ml-2"
      v-if="showSteps"
    >
      <n-step :title="t('rent.review.steps.zero.title')" class="mr-4" v-if="reviewToOwner"
        ><div class="n-step-description pt-4" v-if="current === 1">
          <n-form-item path="toolRate">
            <n-rate v-model:value="model.toolRate"
          /></n-form-item>
          <n-form-item path="toolReview">
            <n-input
              v-model:value="model.toolReview"
              type="textarea"
              :maxlength="MAX_REVIEW_LENGTH"
              :minlength="MIN_REVIEW_LENGTH"
              :placeholder="t('rent.review.steps.zero.form.content.placeholder')"
              show-count
          /></n-form-item>
          <navigations-button-section
            :current="current"
            :step="1"
            isInitStep
            :totalSteps="totalSteps"
            @clickNext="next"
            :loading="isLoading"
            :canContinue="canContinue"
          /></div
      ></n-step>
      <n-step
        :title="
          t('rent.review.steps.one.title', {
            person: reviewToOwner
              ? t('commons.persons.owner')
              : t('commons.persons.renter'),
          })
        "
        class="mr-4"
        ><div class="n-step-description pt-4" v-if="current === 2">
          <n-form-item path="userRate">
            <n-rate v-model:value="model.userRate"
          /></n-form-item>
          <n-form-item path="userReview">
            <n-input
              v-model:value="model.userReview"
              type="textarea"
              :maxlength="MAX_REVIEW_LENGTH"
              :minlength="MIN_REVIEW_LENGTH"
              :placeholder="t('rent.review.steps.one.form.content.placeholder')"
              show-count /></n-form-item
          ><navigations-button-section
            :current="current"
            :step="2"
            :totalSteps="totalSteps"
            @clickNext="next"
            @clickBack="prev"
            :canContinue="canContinue"
          /></div
      ></n-step>
      <n-step :title="t('rent.review.steps.two.title')"
        ><div class="n-step-description pt-4" v-if="current === 3">
          <n-alert
            :title="t('rent.review.steps.two.confirmationDialog.title')"
            type="success"
          >
            {{ t("rent.review.steps.two.confirmationDialog.content") }} </n-alert
          ><navigations-button-section
            class="mt-4"
            :current="current"
            :step="3"
            :totalSteps="totalSteps"
            @clickNext="next"
            @clickBack="prev"
            isFinalStep
          /></div></n-step></n-steps
  ></n-form>
</template>

<style lang="scss" scoped>
.n-step-description {
  margin: 0 auto;
  max-width: 600px;
}

.stepper {
  margin: 0 auto;
  max-width: 79.5%;
}

:deep(.n-form-item .n-form-item-blank) {
  text-align: start;
}

.n-step-description :deep(.n-form-item-label__asterisk) {
  visibility: hidden;
}

.n-step-description :deep(.n-dynamic-input .n-dynamic-input-item) {
  margin-bottom: unset;
}
</style>

<script>
import { defineComponent, ref, onMounted, toRefs, computed } from "vue";
import { NSteps, NStep, NForm, NFormItem, NAlert, NRate, NInput } from "naive-ui";
import responsive from "@/mixins/responsive";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import NavigationsButtonSection from "@/components/publication/maintainers/NavigationsButton.vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "ReviewStepper",
  components: {
    NForm,
    NFormItem,
    NSteps,
    NStep,
    NavigationsButtonSection,
    NRate,
    NInput,
    NAlert,
  },
  mixins: [responsive],
  props: {
    rent: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const router = useRouter();
    const rentRef = toRefs(props).rent;
    const currentRef = ref(1);
    const totalStepsRef = ref(3);
    const currentStatusRef = ref("process");
    const isLoadingRef = ref(false);
    const formRef = ref(null);
    const reviewRef = ref({});
    const currentUser = store.getters["auth/user_id"];
    const reviewToOwnerRef = ref(false);
    const MIN_REVIEW_LENGTH = 25;
    const MAX_REVIEW_LENGTH = 1500;
    const showStepsRef = ref(false);

    const initReview = async () => {
      // Primero se trata de recuperar algun review ya existente
      reviewRef.value = await store.dispatch("review/getReviewByUserAndRent", {
        from: currentUser,
        rentId: rentRef.value.id,
      });

      if (reviewRef.value === null) {
        initNewReview();
      }
    };

    const initNewReview = () => {
      reviewRef.value = {
        from: currentUser,
        to:
          currentUser === rentRef.value.user
            ? rentRef.value.publication.user
            : rentRef.value.user,
        userRate: 0,
        toolRate: reviewToOwnerRef.value ? 0 : null,
        userReview: "",
        toolReview: reviewToOwnerRef.value ? "" : null,
        datetime: new Date(),
        publicationID: rentRef.value.publication.id,
        rentId: rentRef.value.id,
      };
    };
    onMounted(async () => {
      showStepsRef.value = false;
      reviewToOwnerRef.value = currentUser === rentRef.value.user;
      if (!reviewToOwnerRef.value) {
        currentRef.value = 2;
      }
      await initReview();
      showStepsRef.value = true;
    });

    const canContinueRef = computed(() => {
      switch (currentRef.value) {
        case 1:
          return (
            reviewRef.value.toolRate > 0 &&
            reviewRef.value.toolReview?.length > MIN_REVIEW_LENGTH
          );
        case 2:
          return (
            reviewRef.value.userRate > 0 &&
            reviewRef.value.userReview?.length > MIN_REVIEW_LENGTH
          );
        case 3:
          return true;
        default:
          return false;
      }
    });
    return {
      t,
      MIN_REVIEW_LENGTH,
      MAX_REVIEW_LENGTH,
      model: reviewRef,
      form: formRef,
      isLoading: isLoadingRef,
      current: currentRef,
      currentStatus: currentStatusRef,
      totalSteps: totalStepsRef,
      reviewToOwner: reviewToOwnerRef,
      canContinue: canContinueRef,
      showSteps: showStepsRef,
      next: async () => {
        if (canContinueRef.value) {
          isLoadingRef.value = true;
          switch (currentRef.value) {
            case 1:
              currentRef.value++;
              break;
            case 2:
              await store.dispatch("app/lockUI");
              await store.dispatch("review/save", reviewRef.value);
              await store.dispatch("app/unlockUI");
              currentRef.value++;
              break;
            case 3:
              router.push({
                name: "my-rents",
              });
              break;
            default:
              if (currentRef.value === null || currentRef.value >= totalStepsRef.value) {
                currentRef.value = 1;
              } else {
                currentRef.value++;
              }
          }
          isLoadingRef.value = false;
        }
      },
      prev: () => {
        if (currentRef.value === 1) currentRef.value = totalStepsRef.value;
        else currentRef.value--;
      },
    };
  },
});
</script>
