<template>
  <n-divider title-placement="left">Resumen</n-divider>
  <n-grid :cols="fullscreen ? '1' : '1 s:3'" responsive="screen"
    ><n-gi span="1" offset="0 s:1"
      ><n-space v-if="publicationTitle" justify="space-between" ref="ellipsis">
        <span>{{ t("rent.resume.labels.tool") }}:</span>
        <n-ellipsis
          :style="`max-width: ${
            (ellipsisWidth * (fullscreen && !isMobile ? 100 : 65)) / 100
          }px`"
        >
          {{ publicationTitle }}
        </n-ellipsis>
      </n-space>
      <n-space justify="space-between" v-if="reservedDates">
        <span>{{ t("rent.resume.labels.reservedDates") }}:</span>
        <span> {{ getTextToShow(reservedDates, reservedDaysCount) }}</span>
      </n-space>
      <n-space justify="space-between" v-if="executedDates">
        <span>{{ t("rent.resume.labels.executedDates") }}:</span>
        <span>{{ getTextToShow(executedDates, executedDaysCount) }}</span>
      </n-space>
      <n-space justify="space-between" v-if="isMobile && reservedDaysCount">
        <span>{{ t("rent.resume.labels.reservedDays") }}:</span>
        <span>{{ reservedDaysCount }}</span>
      </n-space>
      <n-space justify="space-between" v-if="isMobile && executedDaysCount">
        <span>{{ t("rent.resume.labels.executedDays") }}:</span>
        <span>{{ executedDaysCount }}</span>
      </n-space>
      <n-space justify="space-between" v-if="delayedDaysCount">
        <span
          ><n-text strong>{{ t("rent.resume.labels.extraDays") }}:</n-text></span
        >
        <span
          ><n-text strong>{{ delayedDaysCount }}</n-text></span
        >
      </n-space>
      <n-space justify="space-between" v-if="deliveryInfo">
        <span>{{ t("rent.resume.labels.delivery") }}:</span>
        <span
          >{{
            `${
              deliveryInfo.renterWillPickItUp
                ? t("rent.deliveryInformation.labels.pickAt", {
                    location: deliveryInfo.locationInfo.address,
                  })
                : deliveryInfo.locationInfo.address
            }`
          }}
        </span>
      </n-space>
    </n-gi></n-grid
  >
</template>
<style lang="scss" scoped>
.n-card {
  text-align: left;
  max-width: 330px;
  @media screen and (max-width: 799px) {
    max-width: 100%;
  }
}
.n-text.n-text--strong {
  font-weight: 550;
}
</style>

<script>
import { defineComponent, computed, ref } from "vue";
import { formatDates } from "@/shared/utils";
import { NDivider, NGrid, NGi, NSpace, NEllipsis, NText } from "naive-ui";
import { useStore } from "vuex";
import responsive from "@/mixins/responsive";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "RentResumen",
  components: { NDivider, NGrid, NGi, NSpace, NEllipsis, NText },
  props: {
    publicationTitle: { type: String, default: null },
    reservedDates: { type: Object, default: null },
    executedDates: { type: Object, default: null },
    deliveryInfo: { type: Object, default: null },
    fromMaintainer: { type: Boolean, default: false },
    fullscreen: { type: Boolean, default: false },
    reservedDaysCount: { type: Number, default: null },
    executedDaysCount: { type: Number, default: null },
    delayedDaysCount: { type: Number, default: null },
  },
  mixins: [responsive],
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const isMobile = computed(() => store.state.app.isMobile);
    const ellipsisRef = ref(null);
    return {
      t,
      ellipsis: ellipsisRef,
      ellipsisWidth: computed(() => ellipsisRef.value?.offsetWidth),
      getTextToShow: (range, daysCount) => {
        let textToShow = formatDates(range.start, range.end);

        if (range.end === null) {
          textToShow += " - In Process";
        }

        if (!isMobile.value && daysCount) {
          textToShow += ` (${daysCount} day${daysCount > 1 ? "s" : ""})`;
        }
        return textToShow;
      },
    };
  },
});
</script>
